import Vue from 'vue'

import axios from 'axios'
import NotificationType from '@/store/modules/notification/NotificationType'
import notificationsActionMixins from '@/components/mixins/store/notificationsActionMixins'

export default {
  mixins: [notificationsActionMixins],
  data() {
    return {
      currentBuild: Vue.$appSettings.build,
      newBuild: '',
    }
  },
  watch: {
    newBuild(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.notifyBuildChanged()
      }
    },
  },
  methods: {
    initBuildCheck(frequency = 1000 * 60 * 10) {
      this.checkBuild()

      setInterval(() => {
        this.checkBuild()
      }, frequency)
    },
    async checkBuild() {
      try {
        const fileResponse = await axios.get('/config/config.json')
        const receivedBuild = fileResponse.data.vueAppSettings.build

        if (this.currentBuild !== receivedBuild) {
          this.newBuild = receivedBuild
        }
      } catch (error) {
        Vue.$log.error(error)
        Vue.$tracking.error('check build caught an exception', error)
      }
    },
    notifyBuildChanged() {
      this.spawnNotification({
        type: NotificationType.Info,
        title: 'New version is available',
        message:
          'The new version of the ePID dashboard contains bug fixes and provides a better experience.',
        hasNoTimeout: true,
        isModal: true,
        action1: {
          label: 'Update now',
          action: () => {
            this.reloadApp()
          },
        },
      })
    },
    reloadApp() {
      window.location.reload()
    },
  },
  mounted() {
    this.initBuildCheck()
  },
}
