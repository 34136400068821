import { createNamespacedHelpers } from 'vuex'
import loadingState from '@/constants/loadingState'
import NotificationType from '@/store/modules/notification/NotificationType'

export const trialSettingsModule = 'trialSettings'

const trialSettingsGetters = [
  'trialStatus',
  'trialNumber',
  'trialSiteNumber',
  'trialTreatmentArms',
  'trialDosingRegimens',
  'trialTreatmentName',
  'trialTreatmentDefaultSelectedArm',
  'trialLanguages',
  'trialRole',
  'trialPermissions',
  'trialFeatureFlags',
  'trialTreatmentInsulins',
]

const permissionsGetters = ['isUserAllowedToManagePatient']

const { mapGetters } = createNamespacedHelpers(trialSettingsModule)

export const trialSettingsMapGetters = () => mapGetters(trialSettingsGetters)

export const permissionsMapGetters = () => mapGetters(permissionsGetters)

let lastUpdateTrialSettingsRequestedBySiteNo = undefined

export default {
  namespaced: true,
  state: { settings: null, loadingState: loadingState.INITIAL },
  getters: {
    trialStatus: state => state.settings?.protocolConfiguration?.trialStatus,
    trialNumber: state => state.settings?.protocolConfiguration?.protocolNumber,
    trialSiteNumber: state => state.settings?.number,
    trialTreatmentArms: state =>
      state.settings?.protocolConfiguration?.arms || [],
    trialDosingRegimens: state =>
      state.settings?.protocolConfiguration?.dosingRegimens || [],
    trialTreatmentName: state => treatmentId => {
      if (!treatmentId || !state.settings?.protocolConfiguration)
        return treatmentId

      const arms = state.settings?.protocolConfiguration?.arms || []
      const found = arms.find(arm => arm.id === treatmentId)

      return found ? found.displayName : treatmentId
    },
    trialTreatmentInsulins: state =>
      state.settings?.protocolConfiguration?.insulins || [],

    trialFeatureFlags: (state, getters, rootGetters) => patientNumber => {
      let treatmentId
      if (
        patientNumber ===
        rootGetters.selectedPatient.configuration.patientNumber
      ) {
        treatmentId = rootGetters.selectedPatient.configuration.treatmentArmId
      }

      if (!treatmentId || !state.settings?.protocolConfiguration) return {}

      const arms = state.settings.protocolConfiguration.arms || []
      const found = arms.find(arm => arm.id === treatmentId)

      return found.spaFlags || {}
    },
    trialTreatmentDefaultSelectedArm: state => {
      const trialArms = state.settings?.protocolConfiguration?.arms || []
      return trialArms?.length === 1 ? trialArms[0].id : ''
    },
    trialLanguages: state => {
      return state.settings?.protocolConfiguration?.languages || []
    },
    trialRole: state => state.settings?.role,
    trialPermissions: state => state.settings?.permissions || [],

    isUserAllowedToManagePatient: state =>
      (state.settings?.permissions || []).includes('ManagePatient'),
  },
  mutations: {
    UPDATE_TRIAL_SETTINGS(state, payload) {
      state.settings = payload
      state.loadingState = loadingState.LOAD_SUCCEEDED
    },
    TRIAL_SETTINGS_FAILED(state) {
      state.loadingState = loadingState.LOAD_ERRORED
    },
  },
  actions: {
    updateTrialSettingsRequested(
      { commit, rootGetters, dispatch },
      { siteNo = null } = {}
    ) {
      if (lastUpdateTrialSettingsRequestedBySiteNo === siteNo) return

      lastUpdateTrialSettingsRequestedBySiteNo = siteNo

      if (siteNo === null) {
        commit('UPDATE_TRIAL_SETTINGS', {})
      } else {
        const sites = rootGetters['user/userSites']
        const site = sites.find(s => s.number === siteNo)

        if (site) {
          commit('UPDATE_TRIAL_SETTINGS', site)
        } else {
          lastUpdateTrialSettingsRequestedBySiteNo = undefined

          dispatch(
            'notification/spawnNotification',
            {
              type: NotificationType.Error,
              title: 'Trial settings could not be loaded',
              message: 'Please try to reload the site or try again later.',
              hasNoTimeout: true,
              action1: {
                label: 'Reload site',
                action: () => {
                  location.reload()
                },
              },
            },
            { root: true }
          )
        }
      }
    },
  },
}
