
import Vue from 'vue'
import AppBar from '@/components/layout/AppBar.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import PatientNavigation from '@/components/patient/PatientNavigation.vue'
import { selectedPatientMapActions } from '@/store/modules/selectedPatientModule'
import BoxedDiv from '@/components/layout/BoxedDiv.vue'

export default Vue.extend({
  name: 'PatientLayout',
  components: {
    BoxedDiv,
    PatientNavigation,
    AppFooter,
    AppBar,
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (
        newRoute.params.patientNo &&
        newRoute.params.patientNo !== oldRoute?.params?.patientNo
      ) {
        this.loadPatientConfigurationRequested(newRoute.params.patientNo)
      }
    },
  },
  computed: {
    currentPatientNo() {
      return this.$route.params.patientNo
    },
  },

  methods: {
    ...selectedPatientMapActions(),
  },
  created() {
    this.loadPatientConfigurationRequested(this.currentPatientNo)
  },
  beforeDestroy() {
    this.clearPatientConfigurationRequested()
  },
})
