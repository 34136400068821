import { createNamespacedHelpers } from 'vuex'
import getters from './newPatient/getters'
import AuditLog from '@/utils/audit/AuditLog'
import dosingDayOptions from '@/constants/dosingDayOptions'

import prescriptionType from '@/constants/prescriptionType'

export const newPatientModule = 'newPatient'

const newPatientGetters = [
  'newPatientInfo',
  'newPatientInfoAuditEntries',
  'newPatientAppSettings',
  'newPatientAppSettingsAuditEntries',
  'newPatientDoseRules',
  'newPatientFeatureFlags',
  'newPatientPrescription',
]
Object.freeze(newPatientGetters)

const newPatientActions = [
  'newPatientUpdateFeatureFlags',
  'newPatientUpdateInfo',
  'newPatientUpdateInfoAuditLog',
  'newPatientResetInfo',
  'newPatientSetDefaultTreatmentArm',
  'newPatientUpdateInitialDoseToggle',
  'newPatientUpdateAppSettings',
  'newPatientUpdateAppSettingsAuditLog',
  'newPatientResetAppSettings',
  'newPatientClearAll',
  'newPatientUpdateDoseRules',
  'newPatientUpdatePrescriptionValue',
  'newPatientUpdateCarbCountValues',
  'newPatientUpdatePrescriptionBloodGlucoseUnit',
  'newPatientUpdatePrescriptionDosingDay',
  'newPatientUpdatePrescriptionAuditLog',
]
Object.freeze(newPatientActions)

const { mapGetters, mapActions } = createNamespacedHelpers(newPatientModule)

export const newPatientMapGetters = () => mapGetters(newPatientGetters)

export const newPatientMapActions = () => mapActions(newPatientActions)

export const createInitialPatientInfo = () => ({
  treatmentArm: null,
  dosingRegimen: null,
  patientNumber: '',
  patientSex: null,
  birthDay: null,
  birthMonth: null,
  birthYear: null,
})

export const createInitialAppSettings = () => ({
  language: null,
  timeFormat: null,
})

export const createInitialDoseRules = () => []

export const createInitialPrescriptions = () => {}

export const createInitialState = () => ({
  featureFlags: {
    prescriptionsEnabled: true,
  },
  patientInfo: createInitialPatientInfo(),
  appSettings: createInitialAppSettings(),
  doseRules: createInitialDoseRules(),
  treatments: createInitialPrescriptions(),
  patientInfoAuditLog: new AuditLog(),
  appSettingsAuditLog: new AuditLog(),
})

export default {
  namespaced: true,
  state: createInitialState(),
  getters,

  mutations: {
    CLEAR_ALL(state) {
      resetToInitialState(state)
    },
    UPDATE_FEATURE_FLAGS(state, value) {
      state.featureFlags = value
    },
    UPDATE_PATIENT_INFO(state, fields = {}) {
      state.patientInfo = { ...state.patientInfo, ...fields }
    },
    UPDATE_PATIENT_INFO_AUDIT_LOG(state, audit = { name: null, value: null }) {
      state.patientInfoAuditLog.log(audit.name, audit.value)
    },
    RESET_PATIENT_INFO(state) {
      state.patientInfo = createInitialPatientInfo()
    },
    SET_DEFAULT_TREATMENT_ARM_PATIENT_INFO(state, treatmentArm) {
      state.patientInfo = { ...state.patientInfo, treatmentArm }
    },
    UPDATE_INITIAL_DOSE_TOGGLE(
      state,
      { id = null, name = null, enabled = null } = {}
    ) {
      const obj = state.treatments[id]
      obj[name].enabled = enabled
      state.treatments = { ...state.treatments }
    },
    UPDATE_APP_SETTINGS(state, fields = {}) {
      state.appSettings = { ...state.appSettings, ...fields }
    },
    UPDATE_APP_SETTINGS_AUDIT_LOG(state, audit = { name: null, value: null }) {
      state.appSettingsAuditLog.log(audit.name, audit.value)
    },
    RESET_APP_SETTINGS(state) {
      state.appSettings = createInitialAppSettings()
    },
    SET_INITIAL_PRESCRIPTION_VALUE(
      state,
      { id = null, value = null, key = null } = {}
    ) {
      const obj = state.treatments[id]
      obj[key].value = value

      state.treatments = { ...state.treatments }
    },
    SET_INITIAL_CARB_COUNT_VALUES(
      state,
      { id = null, key = null, object = {} } = {}
    ) {
      const obj = state.treatments[id]
      obj[key] = { ...obj[key], ...object }
      state.treatments = { ...state.treatments }
    },
    SET_INITIAL_PRESCRIPTION_BG_UNIT(state, { id = null, value = null } = {}) {
      const obj = state.treatments[id]
      obj.bloodGlucoseUnit = value
      state.treatments = { ...state.treatments }
    },
    SET_INITIAL_PRESCRIPTION_DOSING_DAY(
      state,
      { id = null, value = null } = {}
    ) {
      const obj = state.treatments[id]
      obj.dosingDay = value
      state.treatments = { ...state.treatments }
    },
    UPDATE_INITIAL_PRESCRIPTION_AUDIT_LOG(
      state,
      { id = null, audit = { name: null, value: null } }
    ) {
      const obj = state.treatments[id]
      obj.auditLog.log(audit.name, audit.value)
    },
    UPDATE_DOSE_RULES(state, payload = []) {
      state.doseRules = [...payload]
      state.treatments = {}
      for (const rule of state.doseRules) {
        const doses = {
          dosingDay: null,
          auditLog: new AuditLog(),
          bloodGlucoseUnit: null,
        }
        for (const config of rule.doseConfiguration) {
          if (rule.type === prescriptionType.BOLUS_CARB_COUNT) {
            doses[config.timepoint] = {
              insulinToCarbRatio: null,
              insulinSensitivityFactor: null,
              enabled: config.isActive,
            }
          } else {
            doses[config.timepoint] = {
              value: null,
              enabled: config.isActive,
            }
          }
        }
        state.treatments[rule.prescriptionConfigurationId] = { ...doses }
        const today = new Date().getDay()
        if (
          rule.type === prescriptionType.BASAL_ONCE_WEEKLY ||
          rule.type === prescriptionType.DOUBLE_DUMMY
        ) {
          state.treatments[rule.prescriptionConfigurationId].dosingDay =
            dosingDayOptions[today].value
        }
      }
    },
  },

  actions: {
    newPatientClearAll({ commit }) {
      commit('CLEAR_ALL')
    },
    newPatientUpdateFeatureFlags({ commit }, payload) {
      commit('UPDATE_FEATURE_FLAGS', payload)
    },
    newPatientUpdateInfo({ commit }, payload) {
      commit('UPDATE_PATIENT_INFO', payload)
    },
    newPatientUpdateInfoAuditLog({ commit }, payload) {
      commit('UPDATE_PATIENT_INFO_AUDIT_LOG', payload)
    },
    newPatientResetInfo({ commit }) {
      commit('RESET_PATIENT_INFO')
    },
    newPatientSetDefaultTreatmentArm({ commit, rootGetters }) {
      commit(
        'SET_DEFAULT_TREATMENT_ARM_PATIENT_INFO',
        rootGetters['trialSettings/trialTreatmentDefaultSelectedArm']
      )
    },
    newPatientUpdateInitialDoseToggle({ commit }, payload) {
      commit('UPDATE_INITIAL_DOSE_TOGGLE', payload)
      commit('UPDATE_INITIAL_PRESCRIPTION_AUDIT_LOG', {
        id: payload.id,
        audit: {
          name: `PRESCRIBE_DOSE:${payload.name.toUpperCase()}_NOT_APPLICABLE`,
          value: !payload.enabled,
        },
      })
    },
    newPatientUpdateAppSettings({ commit }, payload) {
      commit('UPDATE_APP_SETTINGS', payload)
    },
    newPatientUpdateAppSettingsAuditLog({ commit }, payload) {
      commit('UPDATE_APP_SETTINGS_AUDIT_LOG', payload)
    },
    newPatientResetAppSettings({ commit }) {
      commit('RESET_APP_SETTINGS')
    },
    newPatientUpdateDoseRules({ commit }, payload) {
      commit('UPDATE_DOSE_RULES', payload)
    },
    newPatientUpdatePrescriptionValue({ commit }, payload) {
      commit('SET_INITIAL_PRESCRIPTION_VALUE', payload)
    },
    newPatientUpdateCarbCountValues({ commit }, payload) {
      commit('SET_INITIAL_CARB_COUNT_VALUES', payload)
    },
    newPatientUpdatePrescriptionBloodGlucoseUnit({ commit }, payload) {
      commit('SET_INITIAL_PRESCRIPTION_BG_UNIT', payload)
    },
    newPatientUpdatePrescriptionDosingDay({ commit }, payload) {
      commit('SET_INITIAL_PRESCRIPTION_DOSING_DAY', payload)
    },
    newPatientUpdatePrescriptionAuditLog({ commit }, payload) {
      commit('UPDATE_INITIAL_PRESCRIPTION_AUDIT_LOG', payload)
    },
  },
}

const resetToInitialState = state => {
  const initialState = createInitialState()
  Object.keys(initialState).forEach(key => (state[key] = initialState[key]))
}
