
import Vue from 'vue'

import AppBar from '@/components/layout/AppBar.vue'
import AppFooter from '@/components/layout/AppFooter.vue'

export default Vue.extend({
  name: 'BasicLayout',
  components: { AppBar, AppFooter },
})
